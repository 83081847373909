<script setup lang="ts">
import type { MenuItem } from "primevue/menuitem";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

defineProps({
  breadcrumbs: {
    type: Array as PropType<MenuItem[]>,
    default: () => [],
  },
});

const breakpoints = useBreakpoints(breakpointsTailwind);
const largerThanSm = breakpoints.greater("sm");
</script>

<template>
  <ClientOnly>
    <Breadcrumb
      v-if="breadcrumbs && breadcrumbs.length > 0"
      :model="breadcrumbs"
      class="bg-transparent"
    >
      <template #item="{ item }">
        <span v-if="item.icon">
          <NuxtLink :to="item.url"><i :class="item.icon" /></NuxtLink>
        </span>
        <span v-else>
          <NuxtLink
            v-if="item.url"
            :to="item.url"
            :class="[
              'cursor-pointer',
              'no-underline',
              'hover:underline',
              largerThanSm ? 'text-xl' : 'text-sm',
            ]"
          >
            {{ item.label }}
          </NuxtLink>
          <span
            v-else
            :class="[
              'text-sm',
              largerThanSm ? 'text-xl' : 'text-sm',
              { 'font-semibold': item.highlight },
            ]"
          >
            {{ item.label }}
          </span>
        </span>
      </template>
    </Breadcrumb>

    <template #fallback>
      <div class="h-[40px] bg-gray-100/50 animate-pulse rounded" />
    </template>
  </ClientOnly>
</template>

<style scoped>
.pi-home {
  cursor: pointer;
  color: var(--p-green-500);
  font-size: 1.5rem;
}
</style>
